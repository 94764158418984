import { getRequest, postRequest, Response } from './helpers';

interface ResolveResponse {
  id: string;
  subscriptionName: string;
  offerId: string;
  planId: string;
  quantity: number;
  subscription: {
    id: number;
    planId: number;
  };
}

export const resolvePurchase = async (
  token: string,
  accessToken: string,
): Promise<Response<ResolveResponse>> => {
  const header = {
    'x-ms-marketplace-token': token,
    authorization: `Bearer ${accessToken}`,
  };

  const uri =
    'https://marketplaceapi.microsoft.com/api/saas/subscriptions/resolve?api-version=2018-08-31';

  return postRequest(uri, undefined, undefined, header);
};

export const activateSubscription = (
  subscriptionId: number,
  body: { planId: number; quantity: number },
  accessToken: string,
): Promise<Response<void>> => {
  const uri = `https://marketplaceapi.microsoft.com/api/saas/subscriptions/${subscriptionId}/activate?api-version=2018-08-31`;
  const header = {
    authorization: `Bearer ${accessToken}`,
  };
  return postRequest(uri, body, undefined, header);
};

export const getPublisherToken = (userToken: string): Promise<Response<any>> => {
  const uri = `${process.env.REACT_APP_FUNC_ENDPOINT}/api/getPublisherToken`;
  const header = {
    authorization: userToken,
  };
  return getRequest(uri, undefined, header);
};

export const triggerWebhook = async (payload: any): Promise<void> => {
  try {
    const uri = `${process.env.REACT_APP_FUNC_ENDPOINT}/api/connectionWebhook`;
    await postRequest(uri, payload);
  } catch (error) {
    console.error(error);
  }
};
